<template>
  <div class="singIn-section">
    <div class="uk-container">
      <div class="form-border">
        <div class="uk-text-center uk-title">
          <h2>{{ $t("form.createNewInstructor") }}</h2>
        </div>
        <v-form ref="form" class="uk-flex-middle" uk-grid>
          <!-- avatar -->
          <div class="uk-width-1-3@m"></div>
          <div class="uk-width-1-3@m my-1 text-center">
            <v-avatar
              class="profile"
              color="grey"
              size="164"
              tile
              style="border: 2px solid grey; border-radius: 10px !important"
            >
              <v-img :src="avatarPreview"></v-img>
            </v-avatar>
            <v-file-input
              :label="$t('form.personalPicture')"
              :rules="[
                (v) => (!!v && !!v.name) || $t('required.personalPicture')
              ]"
              filled
              accept="image/*"
              :append-icon="cameraIcon"
              prepend-icon=""
              @change="onChangePic"
              @click:clear="remove"
              v-model="data.avatar"
            ></v-file-input>
          </div>
          <div class="uk-width-1-3@m my-1"></div>
          <!-- first name -->
          <div class="uk-width-1-3@m my-1">
            <label>{{ $t("form.firstName") }}</label>
            <v-text-field
              v-model="data.first_name"
              :rules="[(v) => !!v || $t('required.firstName')]"
              :placeholder="$t('form.firstName')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <!-- last name -->
          <div class="uk-width-1-3@m my-1">
            <label>{{ $t("form.lastName") }}</label>
            <v-text-field
              v-model="data.last_name"
              :rules="[(v) => !!v || $t('required.lastName')]"
              :placeholder="$t('form.lastName')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <!-- email -->
          <div class="uk-width-1-3@m my-1">
            <label>{{ $t("form.email") }}</label>
            <v-text-field
              v-model="data.email"
              :rules="[
                (v) => !!v || $t('required.email'),
                (v) => /.+@.+/.test(v) || $t('required.validEmail')
              ]"
              :placeholder="$t('form.email')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <!-- ID number -->
          <div class="uk-width-1-3@m my-1">
            <label>{{ $t("form.IDNumber") }}</label>
            <v-text-field
              type="number"
              v-model="data.identity_number"
              :rules="[(v) => !!v || $t('required.IDNumber')]"
              :placeholder="$t('form.IDNumber')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <!-- phone number -->
          <div class="uk-width-1-3@m my-1">
            <label>{{ $t("form.phoneNumber") }}</label>
            <v-text-field
              type="number"
              v-model="data.phone"
              :rules="[(v) => !!v || $t('required.phoneNumber')]"
              :placeholder="$t('form.phoneNumber')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <!-- country -->
          <div class="uk-width-1-3@m my-1">
            <label>{{ $t("form.country") }}</label>
            <v-text-field
              v-model="data.country"
              :rules="[(v) => !!v || $t('required.country')]"
              :placeholder="$t('form.country')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <!-- resume -->
          <div class="uk-width-1-3@m my-1">
            <label>{{ $t("form.resume") }}</label>
            <v-file-input
              filled
              name="file"
              v-model="data.resume"
              :rules="[(v) => (!!v && !!v.name) || $t('required.resume')]"
              :placeholder="$t('form.resume')"
              accept="application/pdf"
              :append-icon="fileIcon"
              prepend-icon=""
            ></v-file-input>
          </div>
          <!-- skills -->
          <!-- <div class="uk-width-1-3@m my-1">
            <label>{{ $t("form.skills") }}</label>
            <v-text-field
              v-model="data.skills"
              :rules="[(v) => !!v || $t('required.skills')]"
              :placeholder="$t('form.skills')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div> -->
          <!-- futureCourses -->
          <div class="uk-width-1-3@m my-1">
            <label>{{ $t("form.futureCourses") }}</label>
            <v-text-field
              v-model="data.futureCourses"
              :rules="[(v) => !!v || $t('required.futureCourses')]"
              :placeholder="$t('form.futureCourses')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <!-- courses have teached -->
          <div class="uk-width-1-3@m my-1">
            <label>{{ $t("form.previousCourses") }}</label>
            <v-text-field
              v-model="data.previousCourses"
              :rules="[(v) => !!v || $t('required.previousCourses')]"
              :placeholder="$t('form.previousCourses')"
              autocomplete="off"
              autocorrect="off"
            ></v-text-field>
          </div>
          <!-- intro video -->
          <div class="uk-width-1-3@m ma-0">
            <label class="mb-3">{{ $t("form.intro") }}</label>
            <v-file-input
              filled
              show-size
              id="intro"
              @change="onChangeVid"
              v-model="data.intro"
              :rules="[
                (v) => (!!v && !!v.name) || $t('required.intro'),
                introDuration < 60 || $t('required.introDuration')
              ]"
              :placeholder="$t('form.intro')"
              accept="video/*"
              :append-icon="videowIcon"
              prepend-icon=""
            ></v-file-input>
          </div>
          <!-- password -->
          <div class="uk-width-1-3@m my-1">
            <label>{{ $t("form.password") }}</label>
            <v-text-field
              v-model="data.password"
              :rules="[
                (v) => !!v || $t('required.password'),
                (v) => v.length >= 10 || $t('required.validPassword')
              ]"
              :placeholder="$t('form.password')"
              autocomplete="off"
              autocorrect="off"
              type="password"
            ></v-text-field>
          </div>
          <!-- confirm password -->
          <div class="uk-width-1-3@m my-1">
            <label>{{ $t("form.confirmPassword") }}</label>
            <v-text-field
              v-model="passwordConfirm"
              :rules="[
                (v) => !!v || $t('required.field'),
                (v) =>
                  v === data.password || $t('required.notIdentecalPassword')
              ]"
              :placeholder="$t('form.confirmPassword')"
              autocomplete="off"
              autocorrect="off"
              type="password"
            ></v-text-field>
          </div>
          <!-- birth date -->
          <div class="uk-width-1-3@m uk-drop-date my-1">
            <label class="uk-form-label">{{ $t("form.birthDate") }}</label>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || $t('required.birthDate')]"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="data.birth_date"
                no-title
                scrollable
                color="#69d1b6"
              >
                <v-spacer></v-spacer>
                <v-btn text @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  color="#69d1b6"
                  @click="$refs.menu.save(data.birth_date)"
                  dark
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <!-- buttons action -->
          <div class="uk-width-1-3@m"></div>
          <div class="uk-width-2-5@m mt-2 text-center">
            <base-button
              @click="signUp()"
              :options="{
                block: true,
                isLoading: loading,
                color: this.primaryColor
              }"
              >{{ $t("form.signup") }}
            </base-button>
            <p>{{ $t("form.bySigning") }}</p>
          </div>
          <div class="uk-width-1-5@m my-5 m-lg-0 text-center">
            - {{ $t("or") }} -
          </div>
          <div class="uk-width-2-5@m ma-0 text-center">
            <base-button :options="{ block: true, color: this.primaryColor }"
              >Google
            </base-button>
            <p>{{ $t("form.signUpThroughGoogle") }}</p>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import BaseButton from "../../../core/Base/Buttons/BaseButton";
import { EventBus } from "../../../main";
import { mapActions } from "vuex";

function notification(group, type, title, duration) {
  return EventBus.$notify({
    group: group,
    type: type,
    title: title,
    duration: duration
  });
}

export default {
  name: "SignUpInstructor",
  components: { BaseButton },
  data: () => ({
    menu: false,
    date: "",
    valid: true,
    loading: false,
    avatarPreview: "",
    introDuration: null,
    data: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      password: "",
      country: "",
      identity_number: "",
      avatar: [],
      type: "instructor",
      birth_date: "",
      resume: [],
      intro: [],
      futureCourses: "",
      previousCourses: ""
      // skills: "x",
    },
    passwordConfirm: ""
  }),
  methods: {
    ...mapActions("Auth", ["register"]),
    onChangePic(e) {
      this.avatar = e;
      if (e) return (this.avatarPreview = URL.createObjectURL(e));
    },
    remove() {
      this.avatarPreview = "";
    },
    onChangeVid(e) {
      if (e) {
        var vid = document.createElement("video");
        var fileURL = URL.createObjectURL(e);
        vid.src = fileURL;
        vid.ondurationchange = (e) => {
          this.introDuration = vid.duration;
        };
      }
    },
    signUp() {
      if (!this.$refs.form.validate())
        return notification(
          "public",
          "error",
          this.$t("form.checkMissingField"),
          4000
        );
      const formdata = new FormData();
      formdata.append("first_name", this.data.first_name);
      formdata.append("last_name", this.data.last_name);
      formdata.append("phone", this.data.phone);
      formdata.append("email", this.data.email);
      formdata.append("password", this.data.password);
      formdata.append("country", this.data.country);
      formdata.append("identity_number", this.data.identity_number);
      formdata.append("avatar", this.data.avatar);
      formdata.append("type", this.data.type);
      formdata.append("birth_date", this.data.birth_date);
      formdata.append("resume", this.data.resume);
      formdata.append("intro", this.data.intro);
      formdata.append("futureCourses", this.data.futureCourses);
      formdata.append("previousCourses", this.data.previousCourses);
      this.register(formdata)
        .then(() => {
          notification(
            "public",
            "success",
            this.$t(`resolveResponse.signUpSuccessfuly`),
            4000
          );
          this.loading = false;
          setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 2000);
        })
        .catch((err) => {
          let errorMessage = err.response.data.message;
          console.log(JSON.stringify(err.response));
          notification(
            "public",
            "error",
            this.$t(`resolveResponse.${errorMessage}`),
            4000
          );
          this.loading = false;
        });
    }
  },
  validations: {
    birthDay: { required }
  }
};
</script>

<style lang="scss">
h2 {
  color: var(--primary-color);
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 0 !important;
  border-radius: 10px;
  background-color: white !important;
}
.v-input__append-inner {
  padding-bottom: 7px;
}
.v-file-input {
  margin-bottom: -15px !important;
  margin-top: 10px !important;
}
</style>
